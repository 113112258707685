import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { isLocal } from "../config/urlConfig";

// reducers
import app from "./app";
import auth from "./auth";
import base from "./base";
// import breadcrumbs from "./breadcrumbs";
// import calendar from "./tools/calendar";
import channel from "./channel";
import channels from "./channels";
import chapter from "../../ui/pages/ChapterPage/common/redux/chapterReducer";
// import chat from "./tools/chat";
// import classmates from "./tools/classmates";
import comments from "./comments";
import course from "../../ui/pages/Course/common/redux/CourseReducer";
import dashboard from "./dashboards";
import extras from "./extras";
import games from "./games";
// import forum from "./tools/forum";
// import glossary from "./tools/glossary";
import ideas from "./ideas/ideas";
import ideasReaction from "./ideas/ideasReaction";
import commentsIdeas from "./ideas/commentsIdeas";
import lives from "./lives";
import mail from "./mail";
import menu from "./menu/menu";
import news from "./news";
// import notes from "./tools/notes";
import onDemand from "./onDemand";
import post from "./post";
import profile from "./profile";
// import protocol from "./tools/protocol";
import reactions from "./reactions";
// import research from "./research";
import routes from "./routes";
import snackbar from "./snackbar";
import survey from "./survey";
import track from "../../ui/pages/TrackPage/common/redux/reducer";
import tracks from "./tracks";
import tutorials from "./tutorials";
// import tutoring from "./tools/tutoring";
// import wiki from "./tools/wiki";
import video from "./video";
import attachment from "./attachment";
import social from "./social";
import diary from "./diary";
import editing from "./editing";
import rightNav from "../../ui/templates/CoursePage/common/redux/reducer";
import evaluation from "./evaluation";
import search from "./search/search";
import warnings from "./warnings";

const rootReducer = combineReducers({
  app,
  attachment,
  auth,
  base,
  // breadcrumbs,
  // calendar,
  channel,
  channels,
  chapter,
  // chat,
  // classmates,
  comments,
  commentsIdeas,
  course,
  dashboard,
  diary,
  editing,
  evaluation,
  extras,
  games,
  // forum,
  // glossary,
  ideas,
  ideasReaction,
  lives,
  mail,
  menu,
  news,
  // notes,
  onDemand,
  post,
  profile,
  // protocol,
  reactions,
  rightNav,
  // research,
  routes,
  search,
  snackbar,
  social,
  survey,
  track,
  tracks,
  tutorials,
  // tutoring,
  // wiki,
  video,
  warnings,
});

const composeEnhancers =
  (isLocal &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;

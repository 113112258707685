import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function Form({
  defaultValues,
  children,
  onSubmit,
  schema = {},
  options = {},
}) {
  const config = {
    resolver: yupResolver(schema),
    defaultValues,
    ...options,
  };
  const methods = useForm(config);
  const { handleSubmit } = methods;
  return <form onSubmit={handleSubmit(onSubmit)}>{children(methods)}</form>;
}
